import axios from "axios";
import { getToken } from "./authHelper";
import { backendBaseURL } from "./constant";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = backendBaseURL;
axiosInstance.defaults.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};
axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
});
axiosInstance.interceptors.request.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("bam-token")
        }
        return Promise.reject(error);
    }
);
export const _APIHandler = {
    instance: axiosInstance,
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
};