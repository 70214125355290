import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchCityLists } from "../../services/meta";

const CitySelect = ({
  inputName = "city_id",
  onChangeCityOption = null,
  selectedValues = [],
  defaultValue = null,
  isMultiple = false,
  isRequired = false,
  OnReset = false,
}) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchCityListsData = async () => {
      try {
        const response = await fetchCityLists();
        const { data } = response;

        if (data.data.cities) {
          const options = data.data.cities.map((city) => ({
            value: city.id,
            label: city.name,
          }));

          setCityOptions(options);

          const selected = options.filter((option) =>
            selectedValues.includes(option.value)
          );

          if (selected.length) {
            setSelectedOptions(selected);
          } else if (defaultValue) {
            const stateOption = options.find(
              (city) => city.value.toString() === defaultValue.toString()
            );
            setSelectedOptions([stateOption]);
          } else if (defaultValue === null || OnReset) {
            setSelectedOptions([]);
          }
        }
      } catch (error) {
        console.error("Error fetching city lists:", error.response?.data);
      }
    };

    fetchCityListsData();
  }, [defaultValue, OnReset]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);

    if (onChangeCityOption) {
      const selectedValues = selected
        ? selected.map((option) => option.value)
        : [];
      onChangeCityOption(selectedValues);
    }
  };

  return (
    <Select
      isMulti={isMultiple}
      name={inputName}
      options={cityOptions}
      value={selectedOptions}
      onChange={handleChange}
      defaultValue={defaultValue}
      isClearable={true}
      required={isRequired}
    />
  );
};

export default CitySelect;
