import React from "react";
import {
  Button,
  DropdownButton,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaLink,
  FaShare,
} from "react-icons/fa";

const ShareListing = ({ title, url, styles = {} }) => {
  // Function to copy the URL to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!");
  };

  // Function to handle mobile sharing via Web Share API
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: url,
        })
        .then(() => console.log("Successfully shared!"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback for browsers that don't support Web Share API
      alert(
        "Sharing is not supported on this browser. Please use the share options below."
      );
    }
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-top">Share this listing</Tooltip>}
      >
        <Button
          onClick={handleShare}
          variant="secondary"
          size="xs"
          style={{ ...styles }}
        >
          <FaShare className="me-2" /> Share
        </Button>
      </OverlayTrigger>

      {/* Fallback Share Dropdown for Desktop or unsupported browsers */}
      {!navigator.share && (
        <DropdownButton id="share-dropdown" title="Share" variant="primary">
          {/* Facebook */}
          <Dropdown.Item
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}`}
            target="_blank"
          >
            <FaFacebook /> Share on Facebook
          </Dropdown.Item>

          {/* Instagram */}
          <Dropdown.Item href={`https://www.instagram.com/`} target="_blank">
            <FaInstagram /> Share on Instagram
          </Dropdown.Item>

          {/* Twitter */}
          <Dropdown.Item
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              url
            )}&text=Check%20out%20this%20listing!`}
            target="_blank"
          >
            <FaTwitter /> Share on Twitter
          </Dropdown.Item>

          {/* WhatsApp */}
          <Dropdown.Item
            href={`https://wa.me/?text=${encodeURIComponent(url)}`}
            target="_blank"
          >
            <FaWhatsapp /> Share on WhatsApp
          </Dropdown.Item>

          {/* Copy Link */}
          <Dropdown.Item onClick={copyToClipboard}>
            <FaLink /> Copy Link
          </Dropdown.Item>
        </DropdownButton>
      )}
    </>
  );
};

export default ShareListing;
