import DefaultLayout from "../components/DefaultLayout";
function ShippingPolicy() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Shipping Policy
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">Shipping Policy</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <div>
              <p style={{ textAlign: "justify" }}>
                As per our business, Shipping Policy is not mandatory
              </p>
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </DefaultLayout>
    </>
  );
}

export default ShippingPolicy;
