import DefaultLayout from "../components/DefaultLayout";
import {
  fetchBannersLists,
  fetchCategoryLists,
  fetchStateWiseDistricts,
  postYourRequirement,
} from "../services/meta";
import { useEffect, useRef, useState } from "react";
import CategorySection from "../components/home/CategorySection";
import BannerSection from "../components/home/BannerSection";
import DynamicListings from "../components/home/DynamicListings";
import { Button, Card, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import CategorySubCategorySelect from "../components/Inputs/CategorySubCategorySelect";
import ReactSelect from "react-select";
import { useLocation } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import { decodeJWT } from "../helpers/authHelper";
function Home({ OnOpenLoginModal }) {
  const location = useLocation();
  const decodedJWT = decodeJWT();
  const searchParams = new URLSearchParams(location.search);
  const isMobile = useIsMobile();
  const [stateDistricts, setStateDistricts] = useState([]);
  const selectInputRef = useRef();
  const [resetForm, setResetForm] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const _fetchDistrictsByState = async () => {
    await fetchStateWiseDistricts()
      .then((response) => {
        const { data } = response;
        setStateDistricts(data.data.state_districts || {});
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);
  const _fetchCategoryLists = async () => {
    await fetchCategoryLists()
      .then((response) => {
        const { data } = response;
        setCategories(data.data.categories || []);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchBannersLists = async () => {
    await fetchBannersLists()
      .then((response) => {
        const { data } = response;
        setBanners(data.data.banners);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
    _fetchBannersLists();
    _fetchDistrictsByState();
  }, []);
  useEffect(() => {
    if (searchParams.has("show_req")) {
      handleShow();
    }
  }, []);
  const onSubmitPostRequirement = async (e) => {
    e.preventDefault();
    const formData = {
      listing_type: e.target.listing_type.value,
      category_id:
        e.target.category_id.value !== undefined
          ? e.target.category_id.value
          : null,
      sub_category_id:
        e.target.category_id.value !== undefined &&
        e.target.sub_category_id &&
        e.target.sub_category_id.value !== undefined
          ? e.target.sub_category_id.value
          : null,
      location_id: e.target.location_id.value,
      description: e.target.description.value,
      required_in_days: e.target.required_in_days.value,
    };
    await postYourRequirement(formData)
      .then((response) => {
        toast.success(response.data.message);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        console.log(err.response.data);
      });
  };
  return (
    <div as="section" className="container content-overlay mb-md-4 pt-5 mt-4">
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        style={{ border: "none" }}
        className="post-requirement"
      >
        <Modal.Header style={{ background: "#161e5f" }} closeButton>
          <Modal.Title as="h5" style={{ color: "white" }}>
            Post Your Requirements
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitPostRequirement}>
            <Row>
              <Form.Label className="form-label col-form-label col-md-3">
                Listing Type
                <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  value={"S"}
                  label="Sale"
                  defaultChecked={true}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  value={"R"}
                  label="Rent"
                />
                <Form.Check
                  inline
                  type="radio"
                  name="listing_type"
                  value={"SR"}
                  label="Service"
                />
              </div>
            </Row>
            <CategorySubCategorySelect
              defaultCategory={null}
              defaultSubCategory={null}
              isCategoryRequired={true}
              isSubCategoryRequired={true}
              OnReset={resetForm}
            />
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Location
              </Form.Label>
              <div className="col-md-9 gy-2">
                <ReactSelect
                  options={stateDistricts}
                  name="location_id"
                  placeholder="All Location"
                  ref={selectInputRef}
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Required within <span className="text-danger">&nbsp;*</span>
              </Form.Label>
              <div className="col-md-9 gy-2">
                <InputGroup>
                  <Form.Select
                    type="number"
                    name="required_in_days"
                    min={1}
                    max={30}
                    maxLength={2}
                    defaultValue={1}
                    required
                  >
                    {[
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                      18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                    ].map((e) => {
                      return <option value={e}>{e}</option>;
                    })}
                  </Form.Select>
                  <InputGroup.Text className="fs-base">Days</InputGroup.Text>
                </InputGroup>
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3">
                Describe your requirements *
              </Form.Label>
              <div className="col-md-9 gy-2">
                <Form.Control
                  as="textarea"
                  name="description"
                  placeholder="Tell us what you need, and we'll help you get quotes"
                  required
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Form.Label className="form-label col-form-label col-md-3"></Form.Label>
              <div className="col-md-9 gy-2">
                <Button type="submit" className="btn btn-primary rounded-pill">
                  Submit
                </Button>
                <Button
                  type="reset"
                  onClick={() => {
                    setResetForm(true);
                    selectInputRef.current.clearValue();
                    setTimeout(() => {
                      setResetForm(false);
                    }, 500);
                  }}
                  className="btn btn-secondary mx-2 rounded-pill"
                >
                  Reset
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {categories?.length > 0 ? (
        <CategorySection categories={categories} isMobile={isMobile} />
      ) : null}
      <div className="row">
        <div
          className="col-md-9"
          style={{ paddingRight: isMobile ? "10px" : "0px" }}
        >
          {banners?.length > 0 ? <BannerSection banners={banners} /> : null}
        </div>
        <div className={isMobile ? "col-md-3 mt-3" : "col-md-3"}>
          <Card border="0" className="shadow nbr">
            <Card.Body>
              <Card.Title as="h5">Tell us what you need</Card.Title>
              <Card.Text>
                Post your requirement to get the best deals from our verified
                sellers.
              </Card.Text>
              <Button
                onClick={() => {
                  if (decodedJWT) {
                    handleShow();
                  } else {
                    OnOpenLoginModal();
                  }
                }}
                className="btn btn-primary w-100 rounded-pill"
              >
                Post Your Requirement
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
      <DynamicListings
        OnOpenLoginModal={OnOpenLoginModal}
        isMobile={isMobile}
      />
    </div>
  );
}

export default Home;
