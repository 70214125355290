import React, { useEffect, useState } from "react";
import "./PwaInstallPrompt.css"; // Import your CSS file
import { MdInstallMobile } from "react-icons/md";
import useIsMobile from "../hooks/useIsMobile";

const PwaInstallPrompt = () => {
  const isMobile = useIsMobile();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  // Check if the app is installed
  const isInstalled = window.matchMedia("(display-mode: standalone)").matches;

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  // Don't show if already installed
  if (isInstalled) {
    return null;
  }

  return (
    <div className="install-prompt">
      {showInstallButton && isMobile && (
        <button className="install-button" onClick={handleInstallClick}>
          <MdInstallMobile className="me-2" /> Install App
        </button>
      )}
    </div>
  );
};

export default PwaInstallPrompt;
