import React, { useEffect, useState } from "react";

const MobileNumberInput = ({
  label = "Mobile Number",
  name = "mobileNumber",
  defaultValue = null,
  isRequired = false,
  isReadonly = false,
  isDisabled = false,
  className = null,
}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const handleChange = (event) => {
    const { value } = event.target;
    // Remove non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "");
    // Limit to 10 characters
    if (numericValue.length <= 10) {
      setMobileNumber(numericValue);
    }
  };
  useEffect(() => {
    if (defaultValue) {
      setMobileNumber(defaultValue.replace(/[^0-9]/g, "").slice(0, 10));
    }
  }, [defaultValue]);
  return (
    <input
      type="text"
      name={name}
      value={mobileNumber}
      onChange={handleChange}
      maxLength="10"
      placeholder={`Enter ${label}`}
      className={`form-control ${className}`}
      readOnly={isReadonly}
      disabled={isDisabled}
      required={isRequired}
    />
  );
};

export default MobileNumberInput;
