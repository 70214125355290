import React, { useState, useEffect } from "react";

const TextInput = ({
  label = "Mobile Number",
  name = "mobileNumber",
  type = "text",
  defaultValue = null,
  isRequired = false,
  isReadonly = false,
  isDisabled = false,
  className = null,
  maxChars = 20,
  OnReset = null,
}) => {
  // Initialize state with an empty string
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    if (OnReset) setInputValue("");
  }, [OnReset]);
  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue.slice(0, maxChars));
    }
  }, [defaultValue, maxChars]);

  const handleChange = (event) => {
    const { value } = event.target;

    // Limit to maxChars
    if (value.length <= maxChars) {
      setInputValue(value);
    }
  };

  return (
    <>
      <input
        type={type}
        className={`form-control ${className}`}
        name={name}
        value={inputValue}
        onChange={handleChange}
        placeholder={`Enter ${label}`}
        readOnly={isReadonly}
        disabled={isDisabled}
        required={isRequired}
      />
      <div
        style={{
          textAlign: "right",
          fontSize: "0.9rem",
          marginTop: "4px",
        }}
      >
        {inputValue.length}/{maxChars} Chars
      </div>
    </>
  );
};

export default TextInput;
