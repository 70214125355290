import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row } from "react-bootstrap";
import { fetchCategoryLists, fetchSubCategoryLists } from "../services/meta";
const CategorySubCategorySelect = ({
  defaultCategory,
  defaultSubCategory,
  isCategoryRequired = false,
  isSubCategoryRequired = false,
  OnReset = false,
}) => {
  const [categories, setCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const _fetchCategoryLists = async () => {
    await fetchCategoryLists()
      .then((response) => {
        const { data } = response;
        if (data.data.categories) {
          const _options = [];
          data.data.categories.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["name"],
            });
          });
          setCategories(_options);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  const _fetchSubCategoryListsByCategory = async (selectedCategory) => {
    await fetchSubCategoryLists(selectedCategory)
      .then((response) => {
        const { data } = response;
        if (data.data.sub_categories) {
          const _options = [];
          data.data.sub_categories.forEach((v) => {
            _options.push({
              value: v["id"],
              label: v["name"],
            });
          });
          setSubCategoryOptions(_options);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchCategoryLists();
  }, []);
  useEffect(() => {
    if (defaultCategory && categories) {
      const categoryOption = categories.find((category) => {
        return category.value.toString() === defaultCategory.toString();
      });
      setSelectedCategory(categoryOption);
      _fetchSubCategoryListsByCategory(defaultCategory);
    } else if (defaultCategory === null || OnReset) {
      setSelectedCategory(null);
      setSubCategoryOptions([]);
    }
  }, [categories, defaultCategory, OnReset]);

  useEffect(() => {
    if (defaultSubCategory) {
      const subCategoryOption = subCategoryOptions.find(
        (subCategory) =>
          subCategory.value.toString() === defaultSubCategory.toString()
      );
      setSelectedSubCategory(subCategoryOption);
    } else if (defaultSubCategory === null || OnReset) {
      setSelectedSubCategory(null);
    }
  }, [selectedCategory, subCategoryOptions, defaultSubCategory, OnReset]);

  const handleCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCategory(selectedOption);
      _fetchSubCategoryListsByCategory(selectedOption.value);
      setSelectedSubCategory(null);
    } else {
      setSelectedCategory(null);
    }
  };

  const handleSubCategoryChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedSubCategory(selectedOption);
    } else {
      setSelectedSubCategory(null);
    }
  };

  return (
    <>
      <Row className="mt-3">
        <Form.Label className="form-label col-form-label col-md-3">
          Category{" "}
          {isCategoryRequired ? (
            <span className="text-danger">&nbsp;*</span>
          ) : null}
        </Form.Label>
        <div className="col-md-9 gy-2">
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={categories}
            name="category_id"
            placeholder="Select a Category"
            required={isCategoryRequired}
            isClearable={true}
          />
        </div>
      </Row>
      <Row className="mt-3">
        <Form.Label className="form-label col-form-label col-md-3">
          Sub-Category{" "}
          {isSubCategoryRequired ? (
            <span className="text-danger">&nbsp;*</span>
          ) : null}
        </Form.Label>
        <div className="col-md-9 gy-2">
          <Select
            value={selectedSubCategory}
            onChange={handleSubCategoryChange}
            options={subCategoryOptions}
            placeholder="Select a SubCategory"
            name="sub_category_id"
            isDisabled={!selectedCategory}
            required={isSubCategoryRequired}
            isClearable={true}
          />
        </div>
      </Row>
    </>
  );
};

export default CategorySubCategorySelect;
