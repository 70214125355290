import {
  Dropdown,
  DropdownButton,
  Pagination,
} from "react-bootstrap";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { useState, useEffect } from "react";

function PaginationComponent(props) {
  const { pageContext, OnFilter } = props;
  const { page, totalRecords, perPage, hasMorePage } = pageContext;

  const [itemsPerPage, setItemsPerPage] = useState(perPage || 10);
  const [pageNumbers, setPageNumbers] = useState([]);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  useEffect(() => {
    const generatePageNumbers = () => {
      const numbers = new Set();
      const range = 2;

      // Always show the first page
      if (totalPages > 1) {
        numbers.add(1);
      }

      if (totalPages <= 5) {
        // If total pages are 5 or less, show all page numbers
        for (let i = 2; i <= totalPages; i++) {
          numbers.add(i);
        }
      } else {
        // Handle ellipses and display max 5 page numbers
        if (page > range + 2) {
          numbers.add("...");
        }

        for (
          let i = Math.max(2, page - range);
          i <= Math.min(page + range, totalPages - 1);
          i++
        ) {
          numbers.add(i);
        }

        if (page < totalPages - range - 1) {
          numbers.add("...");
        }

        // Always show the last page
        if (totalPages > 1) {
          numbers.add(totalPages);
        }
      }

      // Convert Set to Array and sort it
      const uniquePageNumbers = Array.from(numbers);
      return uniquePageNumbers;
    };

    setPageNumbers(generatePageNumbers());
  }, [page, totalRecords, itemsPerPage, totalPages]);

  const handleItemsPerPageChange = (newPerPage) => {
    setItemsPerPage(newPerPage);
    OnFilter(1, newPerPage); // Reset to first page on items per page change
  };

  return (
    <div className="d-flex justify-content-between align-items-center pagination-container">
      <Pagination>
        {page > 1 && (
          <Pagination.Item
            variant="default"
            onClick={() => OnFilter(page - 1, itemsPerPage)}
          >
            <FaArrowCircleLeft /> Previous
          </Pagination.Item>
        )}

        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <Pagination.Item
              key={`ellipsis-${index}`}
              variant="default"
              disabled
            >
              ...
            </Pagination.Item>
          ) : (
            <Pagination.Item
              key={number}
              active={page === number}
              onClick={() => OnFilter(number, itemsPerPage)}
            >
              {number}
            </Pagination.Item>
          )
        )}

        {hasMorePage && (
          <Pagination.Item
            variant="default"
            onClick={() => OnFilter(page + 1, itemsPerPage)}
          >
            Next <FaArrowCircleRight />
          </Pagination.Item>
        )}
      </Pagination>

      <DropdownButton
        id="dropdown-basic-button"
        title={`Show ${itemsPerPage} Records`}
        variant="secondary"
        className="mt-0"
      >
        {[10, 20, 30, 50].map((size) => (
          <Dropdown.Item
            key={size}
            onClick={() => handleItemsPerPageChange(size)}
            active={size === itemsPerPage}
          >
            {size}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
}

export default PaginationComponent;
