import { Badge, Col } from "react-bootstrap";
import LimitedText from "../LimitedText";
import { FaLocationDot } from "react-icons/fa6";
import { FaMobileAlt, FaTag } from "react-icons/fa";
import { useState } from "react";
import OrganicImage from "../../images/organic.png";
import { IoTimeOutline } from "react-icons/io5";
import { GrOrganization } from "react-icons/gr";

const ListingMobileGridView = ({ listing, OnDoListingShortlist }) => {
  const [isShortlisted, setIsShortListed] = useState(
    listing.listing_shortlists && listing.listing_shortlists.length
  );
  return (
    <Col className="p-0">
      <div className={"card card-hover nbr"}>
        <div
          className="card-img-top card-img-hover d-flex nbr"
          style={{
            padding: "5px",
          }}
        >
          {listing.formatted_primary_image_document_file ? (
            <img
              alt="Image"
              light="0"
              src={listing.formatted_primary_image_document_file}
              style={{
                boxSizing: "border-box",
                padding: "0px",
                border: "none",
                margin: "auto",
                display: "block",
                width: "120px",
                height: "120px",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                boxSizing: "border-box",
                padding: "0px",
                border: "none",
                margin: "auto",
                display: "block",
                width: "120px",
                height: "120px",
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            ></div>
          )}

          <span className="img-overlay"></span>
          <div className="position-absolute start-0 top-0 pt-2 ps-2">
            <span
              className="d-table badge custom-badge text-uppercase mb-1"
              style={{ fontSize: "9px" }}
            >
              For {listing.formatted_listing_type}
            </span>
          </div>
          <div
            className="position-absolute end-0 top-0 pt-2 ps-2"
            style={{ paddingRight: "10px" }}
          >
            {listing.listing_type === "S" ? (
              <span
                className={`d-table badge bg-${
                  listing.available_stock ? "success" : "warning"
                } mb-1`}
                style={{ fontSize: "9px" }}
              >
                {listing.available_stock ? "In Stock" : "Out of Stock"}
              </span>
            ) : null}
          </div>
        </div>
        <div className="card-body position-relative p-1 pt-0 pb-3">
          <div className="mb-1">
            <span
              style={{
                fontSize: "9px",
                fontWeight: "normal",
                marginRight: "2px",
              }}
            >
              <IoTimeOutline /> {listing.formatted_posted_ago}
            </span>
            {listing.is_premium ? (
              <Badge
                className={`bg-warning badge mx-1`}
                style={{ fontSize: "8px" }}
              >
                Featured
              </Badge>
            ) : null}
            {listing.organic_document_file ? (
              <img src={OrganicImage} width={"50px"} />
            ) : null}
          </div>
          <h3
            className="h6 mb-1"
            style={{
              display: "flex",
              border: "1px solid #d5d2dc",
              padding: "5px",
              height: "35px",
              fontSize: "0.7rem",
              alignItems: "center",
            }}
          >
            <LimitedText text={listing.title} limit="25" />
          </h3>
          <h3
            className="h6 mb-1"
            style={{
              display: "flex",
              border: "1px solid #d5d2dc",
              padding: "5px",
              height: "30px",
              fontSize: "0.6rem",
              alignItems: "center",
            }}
          >
            <GrOrganization className="me-2" />{" "}
            {listing["user"] && listing["user"]["organization_name"] ? (
              <LimitedText
                text={listing["user"]["organization_name"]}
                limit={"20"}
              />
            ) : (
              <LimitedText
                text={listing["user"]["formatted_username"]}
                limit={"20"}
              />
            )}
          </h3>
          <h3
            className="h6 mb-1"
            style={{
              display: "flex",
              border: "1px solid #d5d2dc",
              padding: "5px",
              height: "30px",
              fontSize: "0.6rem",
              alignItems: "center",
            }}
          >
            <FaLocationDot className="me-2" />
            {/* {listing?.city?.name} */}
            {listing?.district?.name}
          </h3>
          <h3 className="h4 mb-2 mt-3" style={{ fontSize: "1.2rem" }}>
            {listing.formatted_display_price}/
            <span style={{ fontSize: "12px" }}>
              {listing["unit"] ? listing["unit"]["short_name"] : null}
            </span>
            {listing.formatted_display_price !==
            listing.formatted_sales_price ? (
              <span
                className="mx-2 px-1 btn border-0 btn-outline-secondary btn-sm my-0 py-0"
                style={{ fontWeight: "normal" }}
              >
                <FaTag />
                <strike className="mx-1">
                  {listing.formatted_sales_price}
                </strike>
              </span>
            ) : null}
          </h3>
          <span
            className={`btn nbr btn-primary`}
            style={{ padding: "5px", fontSize: "0.8rem" }}
            onClick={() => window.open("/listing/" + listing.slug, "_blank")}
          >
            <FaMobileAlt className="me-2" /> Contact Seller
          </span>
          <span
            className={`btn nbr btn-outline-secondary ${
              isShortlisted ? "text-danger" : ""
            }`}
            style={{ padding: "10px", marginLeft: "5px", fontSize: "0.8rem" }}
            onClick={async () => {
              const _isShortlisted = isShortlisted
                ? await OnDoListingShortlist(listing.id, 0)
                : await OnDoListingShortlist(listing.id, 1);
              setIsShortListed(_isShortlisted);
            }}
          >
            <i className={`fi-heart${isShortlisted ? "-filled" : ""}`}></i>
          </span>
        </div>
      </div>
    </Col>
  );
};

export default ListingMobileGridView;
