import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Button,
  InputGroup,
  FormControl,
  FormSelect,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import StickyNavbar from "../StickyNavbar";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import farmWayLogo from "../../images/logo.svg";
import { FaPlus, FaRegUserCircle, FaSearch } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import {
  fetchStateWiseDistricts,
  listingSuggestions,
} from "../../services/meta";
import { MdChecklist } from "react-icons/md";
import ReactSelect from "react-select";
import { FaEllipsisVertical } from "react-icons/fa6";
import useIsMobile from "../../hooks/useIsMobile";
const styles = {
  container: {
    width: "300px",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
    position: "relative",
  },
  input: {
    padding: "12px",
    fontSize: "16px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid #ccc",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    outline: "none",
  },
  suggestionList: {
    listStyleType: "none",
    padding: "0",
    margin: "8px 0 0 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    maxHeight: "150px",
    overflowY: "auto",
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
  suggestionItem: {
    padding: "10px",
    cursor: "pointer",
    borderBottom: "1px solid #eee",
    transition: "background-color 0.2s ease",
  },
  suggestionItemHovered: {
    backgroundColor: "#f7f7f7",
  },
};
function AppNavBar(props) {
  const navigate = useNavigate();
  const searchFormRef = useRef();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const isMobile = useIsMobile();
  const [navOpen, setNavOpen] = useState(false);
  const [stateDistricts, setStateDistricts] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({});
  const _fetchDistrictsByState = async () => {
    await fetchStateWiseDistricts()
      .then((response) => {
        const { data } = response;
        setStateDistricts(
          [
            { value: "", label: "All Locations" },
            ...data.data.state_districts,
          ] || {}
        );
        if (
          searchParams &&
          Object.fromEntries([...searchParams]) &&
          Object.fromEntries([...searchParams]).hasOwnProperty("location") &&
          Object.fromEntries([...searchParams])["location"] !== "" &&
          Object.fromEntries([...searchParams])["location"] !== "null"
        ) {
          data.data.state_districts.forEach((states) => {
            states["options"].forEach((district) => {
              if (
                district.value.toString() ===
                Object.fromEntries([...searchParams])["location"].toString()
              )
                setDefaultLocation(district);
            });
          });
        } else {
          setDefaultLocation({ value: "", label: "All Locations" });
        }
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };
  const _listingSuggestions = async (queryParams) => {
    await listingSuggestions(queryParams)
      .then((response) => {
        const { data } = response;
        setSuggestions(data.data.listings || {});
      })
      .catch((err) => {
        // console.log(err.response.data);
      });
  };
  useEffect(() => {
    _fetchDistrictsByState();
    if (
      searchParams &&
      Object.fromEntries([...searchParams]) &&
      Object.fromEntries([...searchParams]).hasOwnProperty("query") &&
      Object.fromEntries([...searchParams])["query"] !== "" &&
      Object.fromEntries([...searchParams])["query"] !== "null"
    ) {
      setSearchText(Object.fromEntries([...searchParams])["query"]);
    }
  }, []);
  const searchListing = (_location = null) => {
    const params = new URLSearchParams(window.location.search);
    if (searchText) {
      params.set("query", searchText);
    } else {
      params.delete("query");
    }
    if (_location) {
      params.set("location", _location);
      params.delete("query");
    } else {
      if (
        searchParams &&
        Object.fromEntries([...searchParams]) &&
        Object.fromEntries([...searchParams]).hasOwnProperty("location") &&
        Object.fromEntries([...searchParams])["location"] !== "" &&
        Object.fromEntries([...searchParams])["location"] !== "null"
      ) {
        params.set(
          "location",
          Object.fromEntries([...searchParams])["location"]
        );
      } else {
        params.delete("location");
      }
    }
    window.location.href = `/search?${params.toString()}`;
  };
  return (
    <>
      <Navbar
        as={StickyNavbar}
        bg="light"
        expand="md"
        className={`fixed-top py-2`}
        style={{
          background: "#fff0db !important",
        }}
      >
        <Container>
          <Navbar.Brand
            onClick={() => (window.location.href = "/")}
            style={{ cursor: "pointer" }}
            className="flex-shrink-0 me-2 me-xl-4"
          >
            <img src={farmWayLogo} width={120} alt="FARMWAY" />
          </Navbar.Brand>
          {/* Search visible on desktop */}
          <InputGroup
            size="sm"
            className="d-none d-md-flex ps-2 order-lg-3 mx-5"
          >
            <ReactSelect
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "200px",
                  border: "3px solid #161e5f",
                }),
              }}
              options={stateDistricts}
              value={defaultLocation}
              defaultValue={defaultLocation}
              placeholder="All Locations"
              onChange={(e) => {
                setDefaultLocation(e);
                // localStorage.setItem("location", e.value);
                searchListing(e.value);
              }}
            />
            <FormControl
              type="search"
              placeholder="Looking For ..."
              // style={styles.input}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                // localStorage.setItem("sq", e.target.value);
                if (e.target.value.toString().length >= 2) {
                  _listingSuggestions({ q: e.target.value });
                } else {
                  // localStorage.setItem("sq", null);
                  setSuggestions([]); // Reset suggestions if input is less than 4 characters
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent default form submission behavior
                  searchListing(); // Trigger the search
                }
              }}
              style={{
                background: "#f5f4f8",
                marginLeft: "10px",
                border: "3px solid #161e5f",
              }}
            />
            <Button className="btn btn-primary" onClick={() => searchListing()}>
              <FaSearch />
            </Button>
            {suggestions.length > 0 && (
              <ul
                style={{
                  listStyleType: "none",
                  padding: "0",
                  margin: "0",
                  position: "absolute",
                  top: "100%", // Position directly below the input
                  left: "218px",
                  // width: "200px", // Same width as the input
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                  zIndex: "1000",
                  maxHeight: "400px",
                  overflowY: "auto",
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      window.location.href = "/listing/" + suggestion.slug;
                      // localStorage.setItem("sq", suggestion.title);
                      setSuggestions([]); // Clear suggestions after selection
                      setSearchText("");
                    }}
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {suggestion.title}
                  </li>
                ))}
              </ul>
            )}
          </InputGroup>
          {isMobile ? (
            <Button
              onClick={() => {
                if (props.userLoggedIn) {
                  window.open("https://seller.farmway.in/signin", "_blank");
                } else {
                  window.open("https://seller.farmway.in/signup", "_blank");
                }
              }}
              className="rounded-pill order-lg-3 btn-sm"
            >
              <FaPlus className="me-1" style={{ color: "white" }} /> SELL
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (props.userLoggedIn) {
                  window.open("https://seller.farmway.in/signin", "_blank");
                } else {
                  window.open("https://seller.farmway.in/signup", "_blank");
                }
              }}
              className="rounded-pill order-lg-3 btn-sm"
            >
              <FaPlus
                className="me-1 py-0 my-0"
                size={"32"}
                style={{ color: "white", height: "20px" }}
              />{" "}
              SELL
            </Button>
          )}

          {props.userLoggedIn ? (
            <Dropdown className="d-none d-lg-block order-lg-3 my-n2 me-1">
              <Dropdown.Toggle
                as={Link}
                className="nav-link dropdown-toggle-flush d-flex py-1 px-0"
                style={{ width: "40px", marginLeft: "15px" }}
              >
                <FaRegUserCircle size={32} />
              </Dropdown.Toggle>
              <Dropdown.Menu variant="dark" renderOnMount align="end">
                <div
                  className="d-flex align-items-start border-bottom border-light px-3 py-1 mb-2"
                  style={{ width: "16rem" }}
                >
                  <div className="ps-2">
                    <h6 className="fs-base mb-0 text-light">
                      Hi, {props.userName}
                    </h6>
                  </div>
                </div>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <i className="fi-user me-2"></i>
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    navigate("/shortlists");
                  }}
                >
                  <MdChecklist className="me-2" />
                  My Shortlists
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    localStorage.removeItem("bam-token");
                    window.location.href = "/";
                  }}
                >
                  <i className="fi-logout me-2"></i>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Button
                size="sm"
                variant="link d-none d-lg-block order-lg-4"
                onClick={() => props.OnOpenLoginModal()}
              >
                <i className="fi-user me-2"></i>
                Sign in
              </Button>
            </>
          )}
          <Navbar.Text className="d-lg-none">
            <FaSearch
              size={"20"}
              onClick={() => {
                setSearchOpen(!searchOpen);
                setNavOpen(false);
              }}
            />
          </Navbar.Text>
          <Navbar.Text className="d-lg-none">
            <FaRegUserCircle
              size={"24"}
              onClick={() => {
                setNavOpen(!navOpen);
                setSearchOpen(false);
              }}
            />
          </Navbar.Text>
          <Dropdown className="order-lg-5 my-n2">
            <Dropdown.Toggle
              as={Link}
              className="nav-link dropdown-toggle-flush d-flex py-1"
            >
              <FaEllipsisVertical size={24} />
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount align="end">
              <Dropdown.Item
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/terms-conditions");
                }}
              >
                Terms and Conditions
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/refund-policy");
                }}
              >
                Refund Policy
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate("/shipping-policy");
                }}
              >
                Shipping Policy
              </Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => {
                  navigate("/help");
                }}
              >
                Help
              </Dropdown.Item> */}
              <Dropdown.Item
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact Us
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {searchOpen ? (
            <Nav className="d-md-none px-4 py-3 my-3 bg-light w-100 mt-1">
              <Form.Group className="mb-3">
                <Form.Label>Search</Form.Label>
                <FormControl
                  type="search"
                  placeholder="Looking For ..."
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    // localStorage.setItem("sq", e.target.value);
                    if (e.target.value.toString().length >= 2) {
                      _listingSuggestions({ q: e.target.value });
                    } else {
                      // localStorage.setItem("sq", null);
                      setSuggestions([]); // Reset suggestions if input is less than 4 characters
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default form submission behavior
                      searchListing(); // Trigger the search
                    }
                  }}
                />
                {suggestions.length > 0 && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: "0",
                      margin: "0",
                      position: "absolute",
                      top: "145px", // Position directly below the input
                      // width: "200px", // Same width as the input
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      zIndex: "1000",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          window.location.href = "/listing/" + suggestion.slug;
                          // localStorage.setItem("sq", suggestion.title);
                          setSuggestions([]); // Clear suggestions after selection
                          setSearchText("");
                        }}
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          borderBottom: "1px solid #eee",
                        }}
                      >
                        {suggestion.title}
                      </li>
                    ))}
                  </ul>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <ReactSelect
                  options={stateDistricts}
                  value={defaultLocation}
                  defaultValue={defaultLocation}
                  placeholder="All Locations"
                  onChange={(e) => {
                    setDefaultLocation(e);
                    // localStorage.setItem("location", e.value);
                    searchListing(e.value);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Button
                  className="btn btn-secondary w-100"
                  onClick={() => searchListing()}
                >
                  <FaSearch className="me-2" /> Search
                </Button>
              </Form.Group>
            </Nav>
          ) : null}
          {navOpen ? (
            <Nav className="bg-light w-100 d-lg-none mt-1">
              {props.userLoggedIn ? (
                <>
                  <Nav.Item className="p-2">Hi, {props.userName}</Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <i className="fi-user me-2"></i>
                    My Profile
                  </Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      navigate("/shortlists");
                    }}
                  >
                    <MdChecklist className="me-2" />
                    My Shortlists
                  </Nav.Item>
                  <Nav.Item
                    className="p-2"
                    onClick={() => {
                      localStorage.removeItem("bam-token");
                      window.location.href = "/";
                    }}
                  >
                    <i className="fi-logout me-2"></i>
                    Sign Out
                  </Nav.Item>
                </>
              ) : (
                <Nav.Item className="d-lg-none">
                  <Button
                    size="sm"
                    variant="link order-lg-4"
                    onClick={() => props.OnOpenLoginModal()}
                  >
                    <i className="fi-user me-2"></i>
                    Sign in
                  </Button>
                </Nav.Item>
              )}
            </Nav>
          ) : null}
        </Container>
      </Navbar>
    </>
  );
}

export default AppNavBar;
