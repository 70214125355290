import { CiCircleMore } from "react-icons/ci";
import styled from "styled-components";
import LimitedText from "../LimitedText";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CategoryWrapper = styled.div`
  background-color: rgba(255, 255, 255, 1);
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 10000;
  color: #111112;
  background-color: #fff;
  // border-radius: 10px;
  // min-height: ${(props) => (props.isMobile ? "63px" : "126px")};
`;
const CategoryItem = styled.div`
  padding: ${(props) => (props.isMobile ? "8px" : "16px")};
  text-decoration: none;
  position: relative;
  box-sizing: content-box;
  // border-radius: 12px;
  cursor: pointer;
`;
const CategoryList = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  // border-radius: 12px;
  cursor: pointer;
`;
const CategoryImage = styled.div`
  width: ${(props) => (props.isMobile ? "48px" : "64px")};
  height: ${(props) => (props.isMobile ? "48px" : "64px")};
  position: relative;
  margin: auto;
  min-width: inherit;
  min-height: inherit;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const CategoryItemText = styled.div`
  font-size: ${(props) => (props.isMobile ? "10px" : "14px")};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CategorySection = ({ categories = [], isMobile }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return categories.length ? (
    <>
      <CategoryWrapper>
        {categories.map((e) => {
          if (e.id < (isMobile ? 4 : 10)) {
            return (
              <CategoryItem
                onClick={() =>
                  navigate(`/search?category=${e.id}&categoryName=${e.name}`)
                }
                key={e.id}
                isMobile={isMobile}
                className="text-center"
              >
                <CategoryImage isMobile={isMobile}>
                  <img src={e.formatted_image_file} alt={e.name} />
                </CategoryImage>
                <CategoryItemText isMobile={isMobile}>
                  <LimitedText text={e.name} limit="15" />
                </CategoryItemText>
              </CategoryItem>
            );
          }
          return null;
        })}
        <CategoryItem
          onClick={() => handleShow()}
          isMobile={isMobile}
          className="text-center"
        >
          <CategoryImage isMobile={isMobile}>
            <CiCircleMore size={32} />
          </CategoryImage>
          <CategoryItemText isMobile={isMobile}>View All</CategoryItemText>
        </CategoryItem>
      </CategoryWrapper>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header style={{ background: "#161e5f" }} closeButton>
          <Modal.Title style={{ color: "white" }}>All Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#eee" }}>
          <Row>
            {categories.map((e) => {
              return (
                <Col
                  xs={4}
                  md={2}
                  key={e.id}
                  isMobile={isMobile}
                  className="text-center"
                >
                  <CategoryList
                    className="my-2"
                    onClick={() =>
                      navigate(
                        `/search?category=${e.id}&categoryName=${e.name}`
                      )
                    }
                    style={{ background: "#fff" }}
                  >
                    <CategoryImage isMobile={isMobile}>
                      <img src={e.formatted_image_file} alt={e.name} />
                    </CategoryImage>
                    <CategoryItemText isMobile={isMobile}>
                      <LimitedText text={e.name} limit="15" />
                    </CategoryItemText>
                  </CategoryList>
                </Col>
              );
            })}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  ) : null;
};
export default CategorySection;
