import DefaultLayout from "../components/DefaultLayout";
function AboutUs() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">About Us</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <div>
              <p style={{ textAlign: "justify" }}>
                BHARAT AGRI MART is an agricultural services firm, providing a
                web platform under trade name FARMWAY that designed to serve as
                a comprehensive directory for the agricultural community.
                <br />
                <br />
                At FARMWAY, our mission is to empower farmers by providing a
                robust digital solution where they can sell their produce, rent
                out their farm machinery, and connect directly with buyers.
                <br />
                <br />
                FARMWAY offers a user-friendly interface that allows farmers to
                list a diverse range of products, from grains and vegetables to
                livestock and specialty crops. In addition to selling their
                produce, farmers can also advertise their farm machinery for
                rent, facilitating access to essential equipment for other
                farmers and reducing the high costs associated with ownership.
                <br />
                <br />
                Buyers, from local markets to large-scale distributors, can
                effortlessly browse these listings, compare options, and make
                informed purchasing and renting decisions. Our platform fosters
                direct communication and transactions between farmers and
                buyers, ensuring transparency and fairness in every deal.
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default AboutUs;
