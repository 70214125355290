import AppFooter from "./partials/AppFooter";

const DefaultLayout = (props) => {
  return (
    <>
      {props.children}
      <AppFooter />
    </>
  );
};

export default DefaultLayout;
