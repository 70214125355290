import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    const online = navigator.onLine;
    if (online !== isOnline) {
      if (online) {
        toast.success("You are back online!");
      } else {
        toast.error("You are offline!");
      }
      setIsOnline(online);
    }
  };

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus());
    window.addEventListener("offline", updateNetworkStatus());
    return () => {
      window.removeEventListener("online", updateNetworkStatus());
      window.removeEventListener("offline", updateNetworkStatus());
    };
  }, [isOnline]);
  return null;
};

export default NetworkStatus;
