import DefaultLayout from "../components/DefaultLayout";
function RefundPolicy() {
  return (
    <>
      <DefaultLayout>
        <section className="bg-secondary py-5 pb-0">
          <div className="py-5 pb-0 container">
            <nav aria-label="breadcrumb" className="pt-3 mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Refund Policy
                </li>
              </ol>
            </nav>
            <div className="px-0 col-lg-8 col-md-10">
              <h1 className="pb-2">Refund Policy</h1>
            </div>
          </div>
        </section>
        <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
          <div className="p-5">
            <div>
              <p style={{ textAlign: "justify" }}>
                All payments made for subscriptions or services with Bharat Agri
                Mart are final and non-refundable. Once a payment is processed,
                no refunds will be issued, regardless of the reason, including
                but not limited to:
              </p>
              <ul>
                <li>Non-usage of the service</li>
                <li>Cancellation of the subscription</li>
                <li>Deactivation or deletion of the membership account</li>
              </ul>
              <p style={{ textAlign: "justify" }}>
                We strongly encourage all users to carefully review and consider
                their purchase before completing the transaction.
              </p>
              <p style={{ textAlign: "justify" }}>
                If you have any questions or concerns, please contact our
                customer support team at <a href="mailto:support@bharatagrimart.com">support@bharatagrimart.com</a> before
                making a purchase.
              </p>
            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default RefundPolicy;
