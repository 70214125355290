import React, { useState } from 'react';
import './styles/OTPInput.css'; // Import CSS file for styling

const OTPInput = ({ length, onChangeOTP }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }

    onChangeOTP(newOtp.join(""));
  };

  const handleBackspace = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = ""; // Clear the current input
    setOtp(newOtp);

    if (element.previousSibling) {
      element.previousSibling.focus();
    }

    onChangeOTP(newOtp.join(""));
  };

  return (
    <div className="otp-input-container">
      {otp.map((data, index) => {
        return (
          <input
            type="text"
            maxLength="1"
            key={index}
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => e.key === "Backspace" ? handleBackspace(e.target, index) : null}
            onFocus={(e) => e.target.select()}
            className="otp-input"
          />
        );
      })}
    </div>
  );
};

export default OTPInput;
