import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Dropdown,
  Button,
  Badge,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import ContactSvg from "../images/real-estate/illustrations/contact.svg";
import { contactUsRequest } from "../services/meta";
import { toast } from "react-toastify";
function ContactUs() {
  const onSubmit = async (e) => {
    e.preventDefault();
    await contactUsRequest({
      name: e.target.name.value,
      contact_number: e.target.contact_number.value,
      email: e.target.email.value,
      message: e.target.message.value,
    })
      .then((response) => {
        toast.success(response.data.message);
        e.target.name.value = "";
        e.target.contact_number.value = "";
        e.target.email.value = "";
        e.target.message.value = "";
      })
      .catch((err) => {
        // toast.error(err.response.data.message);
      });
  };
  return (
    <DefaultLayout>
      <section className="bg-secondary py-5 pb-0">
        <div className="py-5 pb-0 container">
          <nav aria-label="breadcrumb" className="pt-3 mb-4">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Contact Us
              </li>
            </ol>
          </nav>
          <div className="px-0 col-lg-8 col-md-10">
            <h1 className="pb-2">Contact Us</h1>
          </div>
        </div>
      </section>
      <section className="position-relative bg-white rounded-xxl-4 mb-5 py-2 zindex-5 container">
        <div className="p-5">
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label" for="name">
                    Full Name
                  </label>
                  <input
                    required
                    name="name"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
              <div className="col-md-4">
                {" "}
                <div className="mb-4">
                  <label className="form-label" for="contact_number">
                    Your Contact Number
                  </label>
                  <input
                    required
                    type="text"
                    name="contact_number"
                    className="form-control form-control-lg"
                  />
                  <div className="invalid-feedback">
                    Please enter your email.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label" for="email">
                    Your Email
                  </label>
                  <input
                    required
                    type="email"
                    name="email"
                    className="form-control form-control-lg"
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label className="form-label" for="message">
                Message
              </label>
              <textarea
                rows="3"
                required
                name="message"
                className="form-control form-control-lg"
              ></textarea>
            </div>
            <div className="pt-2">
              <button
                type="submit"
                className="w-sm-auto w-100 btn btn-primary btn-lg"
              >
                Submit form
              </button>
            </div>
          </Form>
        </div>
      </section>
      <section className="mb-5 pb-2 pb-md-4 pb-lg-5 container">
        <div className="g-4 row row-cols-md-3 row-cols-1">
          <div className="col">
            <a
              href="mailto:example@email.com"
              className="icon-box card-hover h-100 card"
            >
              <div className="card-body">
                <div className="icon-box-media text-primary rounded-circle shadow-sm mb-3">
                  <i className="fi-mail"></i>
                </div>
                <span className="d-block mb-1 text-body">Drop us a line</span>
                <h3 className="h4 icon-box-title mb-0 opacity-90">
                  <a href="mailto:support@bharatagrimart.com">
                    support@bharatagrimart.com
                  </a>
                </h3>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="tel:4065550120" className="icon-box card-hover h-100 card">
              <div className="card-body">
                <div className="icon-box-media text-primary rounded-circle shadow-sm mb-3">
                  <i className="fi-device-mobile"></i>
                </div>
                <span className="d-block mb-1 text-body">Call us</span>
                <h3 className="h4 icon-box-title mb-0 opacity-90">
                  <a href="#">0422&nbsp;315&nbsp;4462</a>
                  <br />
                  <a href="#">044&nbsp;4689&nbsp;3132</a>
                </h3>
                <span className="d-block mb-1 text-body">
                  Between 9 AM and 6 PM
                </span>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="tel:4065550120" className="icon-box card-hover h-100 card">
              <div className="card-body">
                <div className="icon-box-media text-primary rounded-circle shadow-sm mb-3">
                  <i className="fi-whatsapp"></i>
                </div>
                <span className="d-block mb-1 text-body">Whatsapp</span>
                <h3 className="h4 icon-box-title mb-0 opacity-90">
                  <a href="https://wa.me/919363894771">+91&nbsp;9363894771</a>
                </h3>
              </div>
            </a>
          </div>
          <div className="col">
            <a href="#" className="icon-box card-hover h-100 card">
              <div className="card-body">
                <div className="icon-box-media text-primary rounded-circle shadow-sm mb-3">
                  <i className="fi-map"></i>
                </div>
                <span className="d-block mb-1 text-body">Bharat Agri Mart,</span>
                <p>
                  5/208, VPK Garden,
                  <br />
                  Vellanaipatti,
                  <br />
                  Coimbatore,
                  <br />
                  Tamil Nadu 641 048
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
}

export default ContactUs;
