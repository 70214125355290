import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LimitedText = ({ text, limit }) => {
  const isTruncated = text && text.length > limit;

  const truncatedText = isTruncated ? text.substring(0, limit) + "..." : text;

  const renderTooltip = (props) => (
    <Tooltip id="tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      <span>{truncatedText}</span>
    </OverlayTrigger>
  );
};

export default LimitedText;
