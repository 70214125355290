import * as JWT from "jwt-decode";

export const getToken = () => {
    if (localStorage.getItem("bam-token") !== undefined && localStorage.getItem("bam-token") !== "undefined" && localStorage.getItem("bam-token"))
        return localStorage.getItem("bam-token")
    return false
};
export const decodeJWT = () => getToken() ? JWT.jwtDecode(localStorage.getItem("bam-token")) : false;

export const isAuthenticated = () => {
    if (getToken()) {
        const decodedToken = JWT.jwtDecode(localStorage.getItem("bam-token"));
        return decodedToken.exp > (Date.now() / 1000);
    }
    return false;
};