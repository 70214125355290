import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { updateRequirement } from "../services/meta";

function NeedMoreListings() {
  const params = useParams();
  const navigate = useNavigate();
  const _updateRequirement = async (id) => {
    await updateRequirement(id)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/");
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
        navigate("/");
      });
  };
  useEffect(() => {
    if (params.id) _updateRequirement(params.id);
  }, [params]);
  return <>Updating...</>;
}

export default NeedMoreListings;
